@import "setup";

.blank {
    margin-top: 36px;
    display: grid;
    width: 100%;
    gap: 24px;
    grid-template-columns: 1fr;

    &__images {
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: center;
        width: 100%;
        order: 1;
    }

    &__image {
        width: 100%;
        padding-bottom: 56.111%;
        display: flex;
        min-height: 306px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__slider {
        display: flex;
        position: relative;
        width: 100%;

        &::before {
            position: absolute;
            content: '';
            width: 26px;
            height: 72px;
            top: 0;
            left: 0;
            z-index: 50;
            background: linear-gradient(90deg, #14171F 0%, rgba(20, 23, 31, 0.75) 14.58%, rgba(20, 23, 31, 0.00) 100%);
        }

        &::after {
            position: absolute;
            z-index: 50;
            content: '';
            width: 26px;
            height: 72px;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(20, 23, 31, 0.00) 0%, rgba(20, 23, 31, 0.75) 82.81%, #14171F 100%);
        }

        &__row {
            padding-bottom: 16px;
            overflow: hidden;
            overflow-x: scroll;
            scrollbar-width: thin;
            scrollbar-color: $bg-border $bg-secondary;
            display: flex;
            gap: 8px;
            flex-wrap: nowrap;
            width: 100%;
            position: relative;

            &::-webkit-scrollbar {
                width: 100%;
                height: 8px;
                opacity: .5;
            }

            &::-webkit-scrollbar-track {
                background: $bg-secondary;
                opacity: .5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $bg-accent;
                opacity: .5;
                border-radius: 8px;
                border: 1px solid $bg-border;
            }
        }
    }

    &__img {
        display: flex;
        opacity: .5;
        height: calc(72px * 1.50);
        min-width: 25%;

        &.active {
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            border: 1px solid $bg-border;
        }
    }

    &__content {
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: flex-start;
        order: 0;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        width: 100%;
    }

    &__col {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
    }

    &__row {
        display: flex;
        width: 100%;
        gap: 12px;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        .btn {
            cursor: default;

            &__icon {
                padding: 0;
            }
        }
    }

    &__frame {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 4px;
    }

    &__title {
        color: $content-medium;
        font-family: 'Inter';
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    &__text {
        color: $content-medium;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &.bold {
            font-weight: 600;
        }
    }
}

.tag {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 36px;
    margin-top: 36px;

    &__header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__body {
        display: flex;
        flex-direction: column;
        position: relative;
        flex-wrap: nowrap;
        overflow: hidden;
        width: 100%;
        gap: 24px;
    }

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 36px;
        z-index: 0;
        opacity: 0;
        transition: .3s;

        &.active {
            position: relative;
            z-index: 50;
            opacity: 1;
            transition: .3s;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    &__title {
        color: $content-major;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'Inter';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

    &__text {
        color: $content-medium;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'Inter';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    &__row {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__icon {
        width: 24px;
        height: 24px;

        img {
            object-fit: contain;
        }
    }

    &__line {
        display: flex;
        width: 100%;
        justify-content: space-between;
        column-gap: 12px;
        row-gap: 4px;
    }
}

@media all and (max-width: 1050px) {
    .blank {
        grid-template-columns: 1fr;
        margin-top: 24px;

        &__info {
            grid-template-columns: 1fr auto;
        }

        &__col {
            grid-template-columns: 1fr 1fr;
        }

        &__img {
            min-width: 15.183%;
        }

        &__row {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            .btn {
                p {
                    font-size: 13px;
                }

                &__icon {
                    padding: 0;
                }
            }
        }
    }
}

@media all and (max-width: 720px) {
    .blank {
        &__img {
            min-width: 25%;
        }
    }
}

@media all and (max-width: 600px) {
    .blank {
        &__img {
            height: 60px;
        }

        &__info {
            grid-template-columns: 1fr;
        }

        &__row {
            flex-direction: row;
        }
    }

    .tag {
        &__line {
            flex-direction: column;
        }
    }
}