@import "setup";

.banner {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 64px;

    &__row {
        display: flex;
        position: relative;
        gap: 24px;
        align-items: center;
        width: 100%;
    }

    &__item {
        display: flex;
        position: relative;
        background: $bg-blur;
        border: 1px solid $bg-border;
        border-radius: 32px;
        padding: 36px;
        min-width: calc(50% - 10px);
        height: 360px;
        justify-content: flex-start;
        align-items: flex-end;
        z-index: 5;
        overflow: hidden;
        transition: .3s;
    }

    &__img {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__filter {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        z-index: 6;
        background: linear-gradient(180deg, transparent 0%, $bg-absolute 100%);
    }

    &__content {
        position: absolute;
        z-index: 10;
        bottom: 36px;
        left: 36px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: $emotion-white;
    }

    &__price {
        display: flex;
        gap: 4px;
        font-size: 21px;
        align-items: baseline;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: inherit;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $other-green;
        }

        &-currency {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-medium;
        }
    }

    &__actions {
        display: flex;
        gap: 20px;
    }

    &__arrow {
        &-left {
            left: 0;
            top: 50%;
            transform: translate(-53%, -50%);
        }

        &-right {
            right: 0;
            top: 50%;
            transform: translate(53%, -50%);
        }
    }
}

.games {
    position: relative;

    .scroll {
        position: absolute;
        top: -17px;
        left: -45px;
        width: 100vw;
        padding-left: calc(((100vw - 100%)) / 2);
        padding-right: calc(((100vw - 100%)) / 2);
    }
}

.top {
    display: flex;
    gap: 32px;
    align-items: center;

    &__item {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: 208px;
        padding-bottom: 30px;
    }

    &__number {
        display: flex;
        position: relative;
        padding: 40px 0;
        padding-left: 14px;
        justify-content: flex-end;
    }

    &__text {
        display: flex;
        // height: 186px;
        font-family: 'Inter Bold Italic';
        position: relative;
        z-index: 10;
        font-style: italic;
        font-weight: 600;
        font-size: 256px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        hanging-punctuation: first last;
        font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
        background: linear-gradient(131.09deg, #92D619 15%, #06686F 84.06%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-variation-settings: 'slnt' -10;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &__sign {
        z-index: 9;
        width: 62px;
        height: 78px;
        position: absolute;
        top: 40px;
        left: 0;
        font-family: 'Inter Bold Italic';
        font-style: italic;
        font-weight: 600;
        font-size: 96px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        hanging-punctuation: first last;
        font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(131.09deg, #92D619 15%, #06686F 84.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-variation-settings: 'slnt' -10;
    }

    &__content {
        position: absolute;
        z-index: 11;
        top: 0;
        display: flex;
        right: 0px;

        .game-card {
            &__row {
                top: 0;
                left: 0;
                transform: none;
            }
        }
    }
}

@keyframes colored {
    0% {
        background: linear-gradient(0deg, #1D2029, #1D2029),
            linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
    }

    100% {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
            linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
    }
}

@media all and (min-width: 1442px) {
    .banner {
        &__item {
            padding-bottom: 24.25%;
            height: auto;
        }
    }
}

@media all and (max-width: 834px) {
    .banner {
        padding: 0;

        &__row {
            gap: 12px;
        }

        &__item {
            min-width: 100%;
            height: 360px;

            &-first {}
        }

        &__content {
            bottom: 24px;
            left: 24px;
        }

        &__img {
            width: 100%;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &__filter {
            width: 100%;
        }

        &__price {
            font-size: 19px;
        }

        &__arrow {
            display: none;
        }
    }

    .games {
        .scroll {
            left: -16px;
        }
    }
}

@media all and (max-width: 375px) {
    .banner {

        &__row {
            gap: 12px;
        }

        &__item {
            height: 241px;

            &-first {}
        }

        &__img {
            width: 100%;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &__filter {
            width: 100%;
        }

        &__price {
            font-size: 17px;
        }

        &__arrow {
            display: none;
        }
    }
}

@media all and (min-width: 2000px) {
    .banner {
        &__title {
            font-size: 1.2vw;
        }

        &__actions {
            
            
            & .btn {
                height: auto;
                padding-top: 5.42%;
                padding-bottom: 5.42%;
                
                p {
                    font-size: .8vw;
                }

                &__icon {
                    height: 76.2%;
                    width: fit-content;

                    img {
                        height: 100%;
                        width: fit-content;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
