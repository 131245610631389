@import "setup";

.filter {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__btn {
        display: flex;

        .btn {
            &__icon {
                width: 16px;
                height: 16px;
                padding: 0;
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
    }
}

.sides {
    width: 100%;
    margin-top: 36px;

    &__row {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
    }

    &__sidebar {
        width: 100%;
        padding-left: 24px;
    }

    &__content {
        width: 100%;
    }

    &__field {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
    }

    &__title {
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
        text-align: start;
    }

    &__action {
        display: none;
        padding: 6px;
        gap: 12px;
        isolation: isolate;

        &-row {
            display: flex;
            padding: 6px;
            background: $bg-secondary;
            border-radius: 36px;
        }
    }

    &__icon {
        display: flex;
        width: 24px;
        height: 24px;
        position: relative;

        img {
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            max-height: 83.37%;
            object-fit: contain;
            transform: translate(-50%, -50%);
        }

        &-normal {
            opacity: 1;
            transition: .2s ease-in-out;
        }

        &-hover {
            opacity: 0;
            transition: .2s ease-in-out;
        }
    }

    &__clear {
        padding: 3px 4px;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__bottom {
        width: 100%;
        position: fixed;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        padding: 0 32px;
    }

    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%), linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
        border-radius: 48px;

        .text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-major;
        }
    }
}

#filter {
    display: none;
}

.range {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    &__content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
    }

    &__band {
        display: flex;
        gap: 4px;
        align-items: center;

        .price {
            display: flex;
            gap: 2px;

            .number {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 140%;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: $content-medium;
            }

            .value {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: $content-low;
            }
        }

        .text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-hint;
        }
    }

    &__btn {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 24px;
        width: 96px;
        height: 36px;
        background: $bg-secondary;
        border: 1px solid $bg-border;
        border-radius: 36px;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-low;
        }
    }

    &__slider {
        width: 100%;
        padding: 22px 0;
        display: flex;

        div {
            width: 100%;
        }

        .noUi-target {
            background: #434F64;
            border-radius: 2px;
            height: 4px;
            border: none;
            outline: none;
            box-shadow: none;
        }

        .noUi-connect {
            background: linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%), linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
            border-radius: 1px;
        }

        .noUi-horizontal {
            & .noUi-handle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: none;
                background: linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
                    linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
                box-shadow: none;
                outline: none;
                right: -7.5px;

                &::after,
                &::before {
                    display: none;
                }

                &::before {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 12px;
                    content: '';
                    background: $bg-accent;
                    border-radius: 50%;
                }
            }
        }
    }
}

.filter-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
    }

    &__clear {
        padding: 3px 4px;
    }

    &__choices {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    &__choice {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 7.5px 0px;
        gap: 12px;
        cursor: pointer;

        &:hover {
            .filter-form__label {
                .text {
                    color: $content-medium;
                    transition: .2s ease-in-out;
                }
            }
        }

        input[type='radio'] {
            display: none;

            &:checked {
                &+.filter-form__label {
                    .radio {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%), linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
                        border: none;
                        transition: .2s ease-in-out;

                        .radio__icon {
                            display: flex;
                            transition: .2s ease-in-out;
                        }
                    }

                    .text {
                        color: $content-medium;

                        .stars {
                            opacity: 1;
                        }
                    }

                    transition: .2s ease-in-out;
                }

                transition: .2s ease-in-out;
            }
        }

        input[type='checkbox'] {
            display: none;

            &:checked {
                &+.filter-form__label {
                    .checkbox {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%), linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
                        border: none;
                        transition: .2s ease-in-out;

                        .checkbox__icon {
                            display: flex;
                            transition: .2s ease-in-out;
                        }
                    }

                    .text {
                        color: $content-medium;
                    }

                    transition: .2s ease-in-out;
                }

                transition: .2s ease-in-out;
            }
        }

    }

    &__label {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        transition: .2s ease-in-out;

        .radio {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            width: 16px;
            height: 16px;
            gap: 10px;
            border: 1px solid $bg-accent;
            transition: .2s ease-in-out;

            &__icon {
                display: none;
                width: 10px;
                justify-content: center;
                align-items: center;
                height: 10px;
                padding: 0 1.25px;
                transition: .2s ease-in-out;

                img {
                    object-fit: contain;
                    width: 100%;
                    transition: .2s ease-in-out;
                }
            }
        }

        .checkbox {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            gap: 10px;
            border: 1px solid $bg-accent;
            transition: .2s ease-in-out;

            &__icon {
                display: none;
                width: 10px;
                justify-content: center;
                align-items: center;
                height: 10px;
                padding: 0 1.25px;
                transition: .2s ease-in-out;

                img {
                    object-fit: contain;
                    width: 100%;
                    transition: .2s ease-in-out;
                }
            }
        }

        .text {
            flex-grow: 1;
            display: flex;
            gap: 8px;
            color: $content-low;

            .stars {
                opacity: 0.7;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                display: flex;
                align-items: center;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: inherit;
                transition: .2s ease-in-out;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        padding: 0px 4px;
        background: $bg-secondary;
        border-radius: 2px;

        p {
            /* Regular/Regular 13 */

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            text-align: right;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-low;
        }
    }

    &__link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: right;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-link;
        transition: .2s ease-in-out;

        &:hover {
            text-decoration: underline;
            transition: .2s ease-in-out;
        }
    }
}

.stars {
    display: flex;
    gap: 4px;

    &__item {
        width: 16px;
        height: 16px;
        display: flex;

        img {
            object-fit: contain;
            width: 100%;
        }
    }
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 550;
    background: rgba(0, 0, 0, 0.72);
}

@media all and (max-width: 1200px) {
    .filter {
        &__content {
            width: 100%;
        }

        &__search.search {
            flex-grow: 1;
        }
    }
}

@media all and (max-width: 930px) {
    .filter {
        display: flex;

        &__sort {
            display: none;
        }
    }

    .header {
        &__burger {
            display: flex;
        }
    }

    .sides {
        position: relative;
        z-index: 500;

        &__row {
            grid-template-columns: 1fr;
        }

        &__sidebar {
            display: none;
        }
    }

    #filter {
        display: none;
        position: fixed;
        top: 108px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 600;
        width: 642px;
        height: calc(100vh - 108px);
        background: rgba(20, 23, 31, 0.64);
        box-shadow: 0px 0.3px 0px #283346;
        backdrop-filter: blur(36px);
        background: #14171F;
        overflow: hidden;
        border-radius: 24px 24px 0px 0px;

        .sort {
            margin-top: 12px;
        }
    }

    .sort {
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .filter {
        &__sort {
            display: none;
        }
    }

    .sidebar {
        max-width: 642px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 48px;
        align-items: center;
        background: $emotion-black;
        border-radius: 24px;
        overflow-y: hidden;
        padding-bottom: 100px;

        &::-webkit-scrollbar {
            display: none;
        }

        &__content {
            padding: 0 36px;
            overflow: scroll;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0;
            }
        }

        &__header {
            width: 100%;
            height: 56px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: rgba(20, 23, 31, 0.64);
            box-shadow: 0px 0.3px 0px #283346;
            backdrop-filter: blur(36px);
        }

        &__title {
            padding: 12px 0;
            flex-grow: 1;
            text-align: center;
        }

        &__space {
            width: 96px;
        }

        &__clear {
            display: none;
        }

        &__action {
            display: flex;
        }
    }
}

@media all and (max-width: 642px) {
    #filter {
        width: 100%;
    }

    .sidebar {
        padding-bottom: 70px;

        &__bottom {
            padding: 0 12px;
        }
    }
}

@media all and (max-width: 550px) {
    .filter {
        &__btn {
            .btn {
                padding: 8px;

                p {
                    display: none;
                }

                &__icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

@media all and (max-width: 450px) {
    .sidebar {
        &__bottom {
            bottom: 12px;
        }
    }
}