@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

@font-face {
    font-family: 'Inter Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Regular'), url('../fonts/Inter-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Inter Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Italic'), url('../fonts/Inter-Italic.woff') format('woff');
}


@font-face {
    font-family: 'Inter Thin BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Thin BETA'), url('../fonts/Inter-Thin-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Thin Italic BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Thin Italic BETA'), url('../fonts/Inter-ThinItalic-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Extra Light BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Light BETA'), url('../fonts/Inter-ExtraLight-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Extra Light Italic BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Light Italic BETA'), url('../fonts/Inter-ExtraLightItalic-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Light BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Light BETA'), url('../fonts/Inter-Light-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Light Italic BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Light Italic BETA'), url('../fonts/Inter-LightItalic-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Medium'), url('../fonts/Inter-Medium.woff') format('woff');
}


@font-face {
    font-family: 'Inter Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Medium Italic'), url('../fonts/Inter-MediumItalic.woff') format('woff');
}


@font-face {
    font-family: 'Inter Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Semi Bold'), url('../fonts/Inter-SemiBold.woff') format('woff');
}


@font-face {
    font-family: 'Inter Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Semi Bold Italic'), url('../fonts/Inter-SemiBoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Bold'), url('../fonts/Inter-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Inter Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Bold Italic'), url('../fonts/Inter-BoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Inter Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Bold'), url('../fonts/Inter-ExtraBold.woff') format('woff');
}


@font-face {
    font-family: 'Inter Extra Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Bold Italic'), url('../fonts/Inter-ExtraBoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Inter Black';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Black'), url('../fonts/Inter-Black.woff') format('woff');
}


@font-face {
    font-family: 'Inter Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Black Italic'), url('../fonts/Inter-BlackItalic.woff') format('woff');
}

.html,
.body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}


// colors
$primary: linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%),
    linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
$primary-hover: linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%),
    linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%);
$primary-pressed: linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%),
    linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
$primary-disabled: linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%),
    linear-gradient(0deg, #1D2029, #1D2029);
$content-major: #F0F1F5;
$content-medium: #BEC4D1;
$content-low: #788399;
$content-hint: #4F5564;
$content-disabled: #384053;
$content-link: #2A7AF2;
$bg-absolute: #14171F;
$bg-secondary: #242935;
$bg-accent: #434F64;
$bg-border: #283346;
$bg-blur: #14171FA3;
$emotion-green: #21C93B;
$emotion-yellow: #FCC026;
$emotion-purple: #5B2ADD;
$emotion-white: #ffffff;
$emotion-black: #14171F;
$other-notification: #ff3030;
$other-green: #21C93B;
$other-yellow: #FCC026;
$other-red: #EE4B4B;


// size of all elements
$header-height: 64px;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
    outline: none;
    //user-select: none;
}
