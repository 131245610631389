@import "setup";

.main {
    &__container {
        padding: 0 45px;
    }
}

.title {
    padding: 0 3px;
}

.news {
    margin-top: 36px;
    margin-bottom: 36px;

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 24px;
        row-gap: 48px;
    }
}

.main-text {
    font-family: 'Inter';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: $content-medium;
}

.frame {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    &__row {
        display: flex;
        width: 100%;
    }

    &__title {
        font-family: 'Inter';
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        color: $content-medium;
    }

    &__table {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 36px;
    }

    &__item {
        display: flex;
        gap: 24px;
        font-family: 'Inter';
        align-items: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        justify-content: flex-start;

        & .btn {
            width: 100%;
            height: 48px;
        }
    }

    &__head {
        color: $content-medium;
    }

    &__data {
        color: $content-link;
    }
}

.information {
    width: 888px;

    &__row {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 36px;
    }

    & .news-title {
        &:first-of-type {
            margin-top: 0;
        }
    }
}

.novelty {
    min-width: 318px;
    max-width: 318px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    &:hover {
        .novelty__img {
            img {
                width: calc(100% * 1.0566);
                height: auto;
                transition: .3s;
            }
        }

        .novelty__text {
            color: $content-major;
            transition: .3s;
        }
    }

    &__img {
        height: 179px;
        width: 100%;
        position: relative;
        border-radius: 16px 24px 24px 24px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: contain;
            transition: .3s;
        }
    }

    &__time {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-low;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
        transition: .3s;
    }

    &__tag {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 8px;
        gap: 10px;
        background: $bg-secondary;
        border-radius: 16px;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 140%;
            color: $content-medium;
        }
    }
}

.news-title {
    width: 100%;
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: $content-major;
    margin-top: 24px;
}

.news-title-2 {
    width: 100%;
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    color: $content-major;
    margin-top: 24px;
}

.spoiler {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;

    &__head {
        display: flex;
        justify-content: space-between;
    }

    &__body {
        margin-top: 0px;
        height: 0px;
        overflow: hidden;
        color: $content-low;
        font-family: 'Inter';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        transition: .3s;
    }

    &__touch {
        width: 24px;
        height: 24px;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;

        &__icon {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }

    &.clicked {
        .spoiler__body {
            height: auto;
            margin-top: 16px;
            transition: .3s;
        }

        .spoiler__touch {
            transform: rotateX(-180deg);
            transition: .3s;
        }
    }

    & .divider {
        margin-top: 16px;
    }
}

.news-details {
    margin-top: 48px;
    width: 100%;
    padding-right: 114px;

    &__row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 32px;
    }

    &__img {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        overflow: hidden;
        cursor: zoom-in;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 160%;
        color: $content-medium;

        .link {
            color: $content-link;
        }
    }

    &__list {
        list-style: decimal;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 160%;
        color: $content-medium;
        padding-left: 25px;

        span {
            text-decoration: underline;
        }
    }
}

.publish {
    display: flex;
    gap: 4px;

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-low;
    }

    &__list {
        list-style: none;
        display: flex;
        gap: 8px;

        li {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            text-align: right;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-medium;
        }
    }
}

@media all and (max-width: 1058px) {
    .information {
        width: 100%;
        padding: 0 85px;

        &__row {
            gap: 24px;
        }
    }

    .frame {
        gap: 16px;
    }
}

@media all and (max-width: 834px) {
    .news-title {
        font-size: 32px;
    }

    .news-details {
        margin-top: 32px;
        padding-right: 0;

        &__img {
            width: 100%;

            img {
                width: 100%;
            }
        }

        &__text {
            font-size: 17px;
        }
    }

    .main {
        &__container {
            padding: 0 16px;
        }
    }

    .title {
        padding: 0;
    }

    .news {
        margin-top: 32px;
        margin-bottom: 95px;

        &__row {
            column-gap: 16px;
            row-gap: 36px;
            justify-content: center;
        }
    }

    .novelty {
        max-width: 257px;

        &:hover {
            .novelty__img {
                img {
                    width: auto;
                    height: calc(100% * 1.055);
                    transition: .3s;
                }
            }

            .novelty__text {
                color: $content-major;
                transition: .3s;
            }
        }

        &__img {
            img {
                width: auto;
                height: 100%;
            }
        }
    }

    .frame {
        &__table {
            grid-template-columns: 1fr;
            row-gap: 16px;
        }
    }
}

@media all and (max-width: 560px) {
    .novelty {
        max-width: 351px;
        width: 100%;

        &__img {
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .news-title {
        font-size: 24px;
    }

    .main-text {
        font-size: 15px;
    }

    .information {
        width: 100%;
        padding: 0 16px;
    }
}

@media all and (max-width: 375px) {

    .main {
        &__container {
            padding: 0 12px;
        }
    }

    .title {
        padding: 0;
    }

    .news {
        margin-top: 24px;
        margin-bottom: 70px;

        &__row {
            column-gap: 16px;
            row-gap: 16px;
        }
    }

    .novelty {
        &:hover {
            .novelty__img {
                img {
                    height: auto;
                    width: calc(100% * 1.055);
                    transition: .3s;
                }
            }

            .novelty__text {
                color: $content-major;
                transition: .3s;
            }
        }

        &__img {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}