@import "setup";

.content {
    padding-left: 43px;
    padding-right: 43px;

    &__container {
        display: flex;
        align-items: center;
    }
}

.nav-line {
    margin-top: 48px;
}

.videogames {
    margin-top: 16px;

    &__row {
        column-gap: 16px;
    }
}

.game-long {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 188.5px;

    &:hover {
        .game-form__img {
            height: 310px;
            transition: .2s ease-in-out;
        }
    }

    &__preview {
        display: flex;
        position: relative;
        isolation: isolate;
        width: 100%;
        height: 266px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        overflow: hidden;
        z-index: 300;
    }

    &__img {
        position: absolute;
        z-index: 300;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
        transition: .2s ease-in-out;

        img {
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
    }

    &__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-major;

        .discount {
            color: $other-green;
        }

        &-old {
            text-decoration-line: line-through;
            font-weight: 400;
        }

        &-now {
            font-weight: 600;
        }

        &-value {
            font-weight: 600;
            color: $content-low;
        }
    }

    &__notifications {
        position: absolute;
        top: 8px;
        z-index: 301;
        left: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 9px;
    }

    &__fav {
        top: 8px;
        z-index: 301;
        right: 8px;
    }
}

.profile {
    width: 100%;
    display: flex;
    gap: 48px;

    &__ava {
        display: flex;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__img {
        display: flex;
        width: 144px;
        height: 144px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 120%;
        color: $content-medium;
    }

    &__text {
        display: flex;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
        gap: 5px;

        img {
            width: 15px;
            object-fit: contain;
        }
    }

    &__btn {
        display: flex;
        width: auto;
    }
}

.topic {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: $content-medium;
    margin-top: 12px;
}

.profile-form {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    align-items: center;

    &.border {
        width: 90%;
        background: $bg-secondary;
        padding: 32px 32px 36px;
        border-radius: 15px;

        .btn {
            width: 100%;
            height: 48px;

            p {
                font-size: 15px;
            }
        }
    }

    .btn {
        p {
            font-size: 13px;
        }
    }

    &__column {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__title {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: $content-major;
        text-align: center;
    }

    &__text {
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: $content-low;
        text-align: center;
    }

    &__link {
        display: flex;
        padding: 11px 0px;
        align-items: center;
        gap: 4px;

        p {
            text-align: right;
            font-variant-numeric: lining-nums proportional-nums;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: $content-link;
        }

    }

    &__menu {
        display: flex;
        animation: none;
        width: 100%;

        .menu__item {
            width: 100%;
        }

        .menu__divider {
            padding: 0;
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__ava {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        &__logo {
            width: 144px;
            height: 144px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &__img {
            width: 144px;
            height: 144px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &__btn {
            input {
                display: none;
            }
        }
    }

    &__frame {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
            color: $content-low;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }

    &__socials {
        display: flex;
        gap: 24px;
        align-items: center;
    }

    &__social {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

.table {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &.third {
        .table__head, .table__row {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &.fifth {
        .table__head, .table__row {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__head {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 60px;
        justify-items: start;
        gap: 5px;
    }

    &__row {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 60px;
        justify-items: center;
        gap: 5px;
    }

    &__elem {
        font-family: Inter;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $content-medium;
        background-color: $bg-secondary;
        width: 100%;
        height: 100%;
        padding: 0 10px;
    }
}

.fields {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    row-gap: 36px;

    &__item {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0px;
        transition: .3s;

        &.error {
            gap: 4px;
            transition: .3s;

            .fields__error {
                p {
                    height: 18.19px;
                    opacity: 1;
                }
            }

            .fields__input {
                border-color: $other-red;
                transition: .3s;
            }
        }

        &.line {
            .fields__input {
                width: 100%;
            }
        }

        &.fill {
            grid-column: span 2;
        }

        &.grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 12px;
        }

        .nav-line {
            margin-top: 0;

            &__text {
                font-size: 19px;
            }

            .btn {
                height: 36px;
            }
        }
    }

    &__elem {
        display: flex;
        width: 100%;
    }

    &__menu {
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 30;
        padding-top: 2px;

        .menu {
            width: 100%;
            display: flex;
            animation: none;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0;
            }

            &::-webkit-scrollbar-track {
                display: none;
            }

            &::-webkit-scrollbar-thumb {
                display: none;
            }

            &.restrict {
                max-height: 200px;
            }

            &.hide {
                display: none;
            }

            &__item {
                width: 100%;
            }
        }
    }

    &__error {
        margin-left: 16px;
        transition: .3s;

        p {
            height: 0;
            opacity: 0;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: $other-red;
            transition: .3s;
        }
    }

    &__input {
        min-width: none;
        display: flex;
        position: relative;
        width: 100%;
        background: $bg-secondary;
        border: 1px solid $bg-accent;
        border-radius: 50px;
        transition: .3s;

        &.error+.fields__error {
            p {
                height: 18.19px;
                opacity: 1;
            }
        }

        &.error {
            border-color: $other-red;
            transition: .3s;
        }

        &.textarea {
            height: 114px;
            border-radius: 28px;
        }

        input[type="number"] {

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                display: none;
                opacity: 0;
                -webkit-appearance: none;
                margin: 0;
            }
        }

        input,
        &__text,
        select,
        textarea {
            position: relative;
            z-index: 10;
            width: 100%;
            padding: 17.5px 0;
            padding-left: 20px;
            background: transparent;
            border: none;
            resize: none;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-major;

            &::placeholder {
                opacity: 0;
            }

            &:focus,
            &.focus {
                &::placeholder {
                    opacity: 1;
                }
            }

            &:focus+label,
            &.focus+label {

                top: 0;
                left: 24px;
                padding: 2px 8px;
                transition: .3s;

                p {
                    font-size: 13px;
                    transition: .3s;
                }
            }
        }

        ul {
            display: none;
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        }

        label {
            display: flex;
            position: absolute;
            background: #242935;
            white-space: nowrap;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            border-radius: 8px;
            padding: 2px 0px;
            transition: .3s;

            &.inputted {
                top: 0;
                left: 24px;
                padding: 2px 8px;
                transition: .3s;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 100%;
                color: $content-medium;
                transition: .3s;
            }
        }

        .touch {
            cursor: pointer;
            position: relative;
            z-index: 11;
            padding: 19px 19px 19px 0;
            display: flex;
            transition: .2s;
            transform-origin: 100% 50%;

            &.clicked {
                transform: rotateX(-180deg);
                transition: .2s;
            }

            &__icon {
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media all and (max-width: 834px) {
    .content {
        padding-left: 16px;
        padding-right: 16px;
    }

    .videogames {
        &__row {
            justify-content: flex-start;
        }
    }

    .table {
        overflow-x: auto;
        margin-top: 24px;
        width: 100%;
        display: block;
        flex-direction: column;
        gap: 5px;



        &__head {
            display: none;
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 40px;
            justify-items: start;
            gap: 5px;
        }

        &__elem {
            font-family: Inter;
            display: flex;
            justify-content: center;
            font-size: 15px;
            line-height: 140%;
            width: 100%;
            height: 100%;
            padding: 0 8px;
        }
        &__row {
            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
            border-radius: 20px;
            margin-bottom: 10px;
            padding: 10px;
        }
    }

    .profile {
        &__title {
            font-size: 32px;
        }

        &__text {
            font-size: 15px;
        }
    }
}

@media all and (max-width: 550px) {
    .content {
        padding-left: 12px;
        padding-right: 12px;
    }

    .table {
        display: block;
        flex-direction: column;
        gap: 5px;

        &__head {
            display: none;
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 40px;
            justify-items: start;
            gap: 5px;
        }

        &__elem {
            font-family: Inter;
            display: flex;
            justify-content: center;
            font-size: 12px;
            line-height: 140%;
            width: 100%;
            height: 100%;
            padding: 0 8px;
        }
        &__row {
            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
            margin-bottom: 10px;
            padding: 10px;
        }
    }
    .fields {
        grid-template-columns: 1fr;
        row-gap: 32px;

        &__input {
            label {
                .mob {
                    display: none;
                }
            }
        }

        &__item {
            &.fill {
                grid-column: span 1;
            }

            .nav-line {
                .btn {
                    padding: 0;
                    width: 40px;
                    height: 40px;

                    p {
                        display: none;
                    }

                    &__icon {
                        width: 24px;
                        height: 24px;
                        padding: 5px;

                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    .profile {
        flex-direction: column;
        align-items: center;

        &__info {
            width: 100%;
            align-items: center;
        }

        &__title {
            font-size: 24px;
        }
    }
}