@import "setup";

html {
    scroll-behavior: smooth;
}

.background-container {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        filter: blur(12px);
        z-index: -1;
    }
}

.pubg-container {
    @extend .background-container;
    margin-bottom: 100px;

    &::before {
        background-image: url('../images/backgrounds/pubg-bg.jpg');
    }
}

.steam-container {
    @extend .background-container;

    &::before {
        background-image: url('../images/backgrounds/steam-bg.jpg');
    }
}

.centered-container {
    display: flex;
    justify-content: center;
}

.steam-info-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    width: 30%;
    border-radius: 20px;
    background-color: #242935;
    opacity: 0.9;
}
.steam-payment-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    width: 20%;
    border-radius: 20px;
    background-color: #242935;
    opacity: 0.9;
}
.steam-payment-info{
    cursor: pointer;
}
.steam-info-popup{
    position: fixed;
    bottom: 5px;
    background-color: #1D2029;
    left: 50px;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
}


.games {
    width: 100%;
    margin-bottom: 64px;

    &__row {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        align-items: center;
    }

    &__list {
        position: relative;

        &__row {
            display: flex;
            width: 100%;
            gap: 20px;
        }
    }
}

.clear {
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        .clear__text {
            color: $content-major;
            text-decoration: underline;
            transition: .2s ease-in-out;
        }

        .clear__icon {
            &-normal {
                opacity: 0;
                transition: .2s ease-in-out;
            }

            &-hover {
                opacity: 1;
                transition: .2s ease-in-out;
            }
        }
    }

    &__text {
        font-family: 'Inter';
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: right;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
        transition: .2s ease-in-out;
    }

    &__icon {
        position: relative;
        width: 16px;
        height: 16px;
        display: flex;

        &-normal {
            opacity: 1;
            transition: .2s ease-in-out;
        }

        &-hover {
            opacity: 0;
            transition: .2s ease-in-out;
        }

        img {
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 83.37%;
        }
    }
}

#categories {
    .categories {
        &__item {
            width: 318px;
        }
    }
}

.payments {
    box-sizing: border-box;
    display: flex;
    //flex-direction: column;
    width: 100%;
    gap: 24px;

    &__row {
        display: flex;
        width: 110px;
        height: 80px;

        img {
            width: 70px;
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
        }

        input {
            display: none;

            &:checked+label {
                .payment__btn {
                    &.arrow {
                        .arrow-icon {
                            opacity: 1;
                            transition: .3s;
                        }
                    }
                }

                .payment__title {
                    color: $content-major;
                    transition: .3s;
                }

                .payment__text {
                    color: $content-major;
                }

                .payment__icon {
                    opacity: 1;
                    transition: .3s;
                }

            }
        }
    }
}

.payments-topup {
    @extend .payments;
    justify-content: space-around;
    gap: 10px;
    //flex-direction: row; // Изменяем только flex-direction на нужный
}



.selected {
    border: 2px solid #4caf50;
    border-radius: 15px;
}

.sceleton {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    &.card {
        min-width: 236px;
        height: 324px;
        border-radius: 21.017px;
        background: #242935;
    }

    &.category {
        border-radius: 24px;
        background: linear-gradient(90deg, #1E222E 0.17%, rgba(30, 34, 46, 0.64) 46.89%, #212633 99.83%);
        min-width: 318px;
        height: 178px;
        padding: 12px 24px;
    }

    &__content {
        display: flex;
        padding: 12.61px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.203px;
        align-self: stretch;
    }

    &__item {
        border-radius: 4px;
        background: linear-gradient(90deg, #1E222E 0.17%, rgba(30, 34, 46, 0.64) 46.89%, #212633 99.83%);

        &.long {
            height: 24px;
            align-self: stretch;
        }

        &.small {
            width: 107px;
            height: 24px;
        }
    }
}

.payment {
    display: flex;
    cursor: pointer;
    width: 100%;
    gap: 16px;
    padding: 20px 12px;
    align-items: center;
    border-radius: 16px;
    background: $bg-secondary;

    &:hover {
        .payment__title {
            color: $content-major;
            transition: .3s;
        }

        .payment__text {
            color: $content-major;
        }

        .payment__icon {
            opacity: 1;
            transition: .3s;
        }
    }

    &__icon {
        width: 64px;
        height: 36px;
        opacity: 0.5;
        border-radius: 2px;
        align-self: flex-start;
        display: flex;
        justify-content: center;
        transition: .3s;

        img {
            object-fit: contain;
            width: 100%;
        }
    }

    &__content {
        display: flex;
        gap: 4px;
        flex-direction: column;
        flex-grow: 1;
    }

    &__title {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'Inter';
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $content-medium;
        transition: .3s;
    }

    &__text {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'Inter';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $content-low;
        transition: .3s;
    }

    &__btn {
        &.arrow {
            position: static;

            .arrow-icon {
                opacity: 0;
                transition: .3s;
            }
        }
    }
}

.payment-topup {
    @extend .payment;
    padding: 5px; // Изменяем только padding
}

.box {
    display: flex;
    width: 100%;
    gap: 36px;
    justify-content: center;
    align-items: center;

    &.v {
        flex-direction: column;
    }
}

.counter {
    display: flex;
    height: 40px;
    padding: 0 2px;
    justify-content: space-between;
    align-items: center;
    min-width: 145px;
    border-radius: 48px;
    background: $bg-blur;
    border: 1px solid #158B1A;
    backdrop-filter: blur(6px);
    transition: .3s;

    &:hover {
        box-shadow: 0px 0px 24px 0px rgba(35, 128, 13, 0.48);
        transition: .3s;
    }

    &__icon {
        width: 40px;
        height: 40px;
        display: flex;
        padding: 12px;
        cursor: pointer;

        &.plus {
            img {
                padding: 2px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__text {
        color: $content-major;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}

.payform {
    display: flex;
    width: 100%;
    padding: 32px;
    gap: 24px;
    flex-direction: column;
    background: $bg-secondary;
    border-radius: 12px;

    &__link {
        cursor: pointer;
        display: flex;
        gap: 4px;
        align-items: center;

        p {
            color: #3686ff; // $content-link
            font-variant-numeric: lining-nums proportional-nums;
            font-family: 'Inter';
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }

        img {
            padding: 2px;
            height: 18px;
            object-fit: contain;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    &__frame {
        display: flex;
        width: 100%;
        padding-top: 12px;
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .btn {
            width: 100%;
            height: 48px;
        }

        &.extra {
            .payform__text {
                color: $content-low;
            }

            .payform__price {

                p,
                span {
                    color: $content-low;
                }
            }
        }
    }

    &__text {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'Inter';
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $content-medium;
    }

    &__price {
        font-family: 'Inter';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: flex;
        gap: 8px;

        p {
            font-weight: 600;
            color: $content-major;
        }

        span {
            color: $content-low;
        }
    }
}

.window {
    width: 100%;
    margin-top: 36px;
    margin-bottom: 46px;

    &__row {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 432px;
        gap: 24px;
    }

    &__side {}
}

.bought-card {
    width: 100%;
    display: flex;
    grid-template-columns: auto 1fr auto;
    gap: 16px;
    padding: 8px 10px 8px 12px;
    align-items: center;
    border-radius: 4px 16px 16px 4px;
    background: $bg-secondary;

    &.deleted {
        padding: 12px 0;
        display: flex;
        justify-content: center;
        background: none;
        align-items: center;

        div {
            display: none;
        }

        .bought-card__deleted {
            display: flex;
        }
    }

    &__deleted {
        display: flex;
        cursor: pointer;
        gap: 4px;
        align-items: center;
        display: none;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            height: 1px;
            width: 0;
            background: $content-link;
            transition: .3s;
        }

        &:hover {
            &::before {
                width: 100%;
                transition: .3s;
            }
        }

        p {
            color: $content-link;
            font-variant-numeric: lining-nums proportional-nums;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }

        img {
            width: 16px;
            height: 16px;
            object-fit: cover;
        }
    }

    &__img {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        border-radius: 2px;
        opacity: 0.5;

        img {
            width: 44px;
            height: 56px;
        }
    }

    &__content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .path {
            margin: 0;
            padding: 0;
        }
    }

    &__small {
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $content-medium;
    }

    &__text {
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        font-variant-numeric: lining-nums proportional-nums;
        color: $content-medium;

        &.old {
            color: $content-low;
            text-decoration: line-through;
        }
    }
}

.path {
    width: 100%;
    padding: 0 48px;
    margin-bottom: 36px;

    &__row {
        display: flex;
        gap: 4px;
        align-items: center;
        flex-wrap: wrap;
    }

    &__link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: right;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;

        &.special {
            color: $content-link;
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4.5px 5.5px;

        img {
            display: flex;
            width: 100%;
        }
    }
}

#modal {
    position: relative;
    display: flex;
}

.wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
    background: $bg-absolute;
    z-index: 0;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 8px;
        /* ширина всей полосы прокрутки */
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        /* цвет зоны отслеживания */
    }

    &::-webkit-scrollbar-thumb {
        background-color: $bg-secondary;
        border-radius: 15px;
        /* цвет бегунка */
    }
}

.empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__row {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
    }

    &__icon {
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'salt' on;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: "Inter Black";
        font-size: 256px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        background: linear-gradient(162deg, #92D619 0%, #06686F 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        img {
            width: 256px;
            height: 256px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    &__title {
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: $content-medium;
    }

    &__text {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $content-low;
    }

    &__btns {
        display: flex;
        gap: 24px;
    }
}

.header {
    width: 100%;
    height: $header-height;
    padding: 0px 48px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    background: $bg-blur;
    backdrop-filter: blur(36px);

    &__container {
        position: relative;
        z-index: 901;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        width: 100%;
        height: 100%;
    }

    &__row {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        gap: 12px;
        align-items: center;
    }

    &__icon {
        display: flex;
        gap: 8px;
        align-items: center;
        border-left: 1px solid $bg-border;
        padding-left: 16px;

        img {
            width: 24px;
            height: 24px;
            object-fit: cover;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            white-space: nowrap;
            font-size: 13px;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-medium
        }
    }
}

@keyframes appearance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes disappearance {

    1% {
        opacity: 1;
    }

    99% {
        opacity: 0;
    }

    100% {
        display: none;
    }
}

.sort {
    display: flex;
    gap: 8px;

    &__item {
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 9px 20px;
        border-radius: 48px;
        border: 1px solid transparent;
        background: transparent;
        transition: .2s ease-in-out;

        &.active {
            background: $bg-accent;
            cursor: default;

            &:hover {
                border: 1px solid transparent;
            }
        }

        &:hover {
            border: 1px solid $bg-accent;
            transition: .2s ease-in-out;
        }
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-major;
        white-space: nowrap;
    }
}

.logo {
    padding: 12px 0;

    &__row {
        cursor: pointer;
        display: flex;
        position: relative;
        z-index: 999;
        flex-direction: row;
        gap: 19px;
        align-items: center;
    }

    .logo__line {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-normal {
            opacity: 1;
        }

        &-hover {
            opacity: 0;
        }

        img {
            position: absolute;
            transition: .5s;
        }
    }

    .logo__menu {
        position: absolute;
        top: 100%;
        left: 0;
        padding-top: 16px;
        display: flex;
    }

    &:hover {
        .logo__line-normal {
            opacity: 0;
            transition: .5s;
        }

        .logo__line-hover {
            opacity: 1;
            transition: .5s;
        }

        .menu {
            display: flex;
            animation: appearance .5s;
        }
    }
}

.burger {
    display: none;
    min-width: 48px;
    height: 48px;
    padding: 6px;

    &__icon {
        display: flex;
        width: 100%;
        height: 100%;
        background: $bg-secondary;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
}

.flow {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.search {
    max-width: 450px;
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    position: relative;
    height: 40px;
    border-radius: 48px;
    border: 1px solid $bg-border;
    background: rgba(20, 23, 31, 0.64);
    backdrop-filter: blur(64px);
    padding-right: 0;
    transition: .5s;

    &.full {
        width: 100%;
    }

    &.focus {
        background: $emotion-white;
        border-color: transparent;

        .search__close {
            display: flex;
        }

        &:hover {
            border-color: transparent;
            transition: .5s;
        }
    }

    &:hover {
        border-color: $bg-accent;
        cursor: text;
        transition: .5s;
    }

    &__row {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__icon {
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    &__input {
        display: flex;
        cursor: inherit;
        width: 100%;
        position: relative;
        align-items: center;
        padding: 9px 0;
        height: 100%;

        input {
            display: flex;
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            font-family: 'Inter';
            padding-left: 46px;
            padding-right: 57px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;

            &::placeholder {
                color: $content-hint;
            }
        }
    }

    &__close {
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        position: absolute;
        display: none;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin-left: 21px;
    }
}

.nav-bottom {
    display: none;
    position: fixed;
    bottom: 0;
    height: 83px;
    z-index: 600;
    left: 0;
    width: 100%;
    background: $bg-blur;
    box-shadow: 0px -0.3px 0px #283346, 0px -2px 24px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(18px);

    &__row {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-content: center;
    }

    &__item {
        display: flex;
        padding: 5px 0;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        &.active {
            .nav-bottom__text {
                background: linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%), linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            .nav-bottom__icon {
                &-normal {
                    opacity: 0;
                }

                &-active {
                    opacity: 1;
                }
            }
        }
    }

    &__text {
        width: 100%;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        text-align: center;
        color: $content-low;
    }

    &__content {
        width: 30px;
        height: 28px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        position: relative;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-normal {
            opacity: 1;
        }

        &-active {
            opacity: 0;
        }
    }

    &__notif {
        position: absolute;
        width: 4px;
        height: 4px;
        left: 24px;
        top: 0px;

        &.hidden {
            visibility: hidden;
        }
    }
}

.caption {
    width: 100%;
    height: 48px;
    display: grid;
    grid-template-columns: minmax(48px, auto) 1fr minmax(48px, auto);

    &__middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.round-btn {
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 5px;

    &:hover {
        .round-btn__row {
            transition: .3s;

            &.back {
                border-color: $bg-accent;
            }
        }

        .round-btn__icon {
            &-normal {
                opacity: 0;
                transition: .3s;
            }

            &-hover {
                opacity: 1;
                transition: .3s;
            }
        }
    }

    &__row {
        display: flex;
        padding: 6px;
        border-radius: 50%;
        transition: .3s;

        &.back {
            background: $bg-secondary;
            border: 1px solid $bg-secondary;
        }
    }

    &__icon {
        position: relative;
        height: 24px;
        width: 24px;
        display: flex;

        img {
            position: absolute;
            width: 100%;
            padding: 6px;
            object-fit: contain;
        }

        &-normal {
            opacity: 1;
            transition: .3s;
        }

        &-hover {
            opacity: 0;
            transition: .3s;
        }
    }

    &__indicator {
        position: absolute;
        display: flex;
        top: 4px;
        right: 2px;
    }
}

.arrow {
    cursor: pointer;
    position: absolute;
    z-index: 20;
    display: flex;
    background: rgba(20, 23, 31, 0.64);
    border-radius: 48px;

    &-left {
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &-right {
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        .arrow {
            &-content {
                border: 2px solid $bg-accent;

                transition: .5s;
            }

            &-icon {
                transition: .5s;

                &__normal {
                    opacity: 0;
                    transition: .5s;
                }

                &__hover {
                    opacity: 1;
                    transition: .5s;
                }
            }
        }
    }

    &-content {
        transition: .5s;
        width: 40px;
        height: 40px;
        padding: 8px;
        display: flex;
        background: $bg-blur;
        border: 2px solid $bg-border;
        backdrop-filter: blur(1px);
        border-radius: 48px;
    }

    &-content-banner {
        transition: .5s;
        width: 60px;
        height: 60px;
        padding: 8px;
        display: flex;
        background: $bg-blur;
        border: 2px solid $bg-border;
        backdrop-filter: blur(1px);
        border-radius: 48px;
    }

    &-icon {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;

        img {
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__normal {
            opacity: 1;
            transition: .5s;
        }

        &__hover {
            opacity: 0;
            transition: .5s;
        }
    }
}

.indicator-dot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    width: 4px;
    height: 4px;
    left: calc(50% - 4px/2);
    top: calc(50% - 4px/2);
    background: $other-notification;
    border-radius: 9px;
}

.btn {
    display: flex;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    padding: 0px 24px 1px;
    gap: 8px;
    cursor: pointer;
    border-radius: 48px;
    transition: .5s;

    &:hover {
        filter: drop-shadow(0px 0px 24px rgba(35, 128, 13, 0.48));
        transition: .5s;
    }

    &.line {
        width: 100%;
    }

    &-light {
        border: none;

        background: linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%),
            linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        transition: .5s;

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);

            transition: .5s;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-major;
        }
    }

    &-medium {
        background: linear-gradient(0deg, rgba(20, 23, 31, 0.64), rgba(20, 23, 31, 0.64)),
            linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%),
            linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

        background: rgba(20, 23, 31, 0.64);
        backdrop-filter: blur(1px);
        border: 1px solid #158B1A !important;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $content-medium;

        }
    }

    &-strong {
        background: $bg-blur;
        border: 1px solid rgba(40, 51, 70, 1);
        backdrop-filter: blur(1px);

        &:hover {
            filter: none !important;
            border: 1px solid rgba(67, 79, 100, 1) !important;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: $content-medium;
        }
    }

    &__icon {
        display: flex;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;
        padding: 2px;

        img {
            width: 100%;
            height: 100%;
        }

        &-arrow {
            img {
                height: 9px;
            }
        }
    }
}

.navigation {
    display: flex;
    gap: 12px;
    align-items: center;

    &__item {
        cursor: pointer;
        display: flex;
        position: relative;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        transition: .5s;
        border-radius: 50%;

        &:hover {
            transition: .5s;
            background-color: $bg-accent;

            .navigation__icon-normal {
                opacity: 0;
            }

            .navigation__icon-hover {
                opacity: 1;
            }


        }

        &-profile {
            cursor: pointer;
            display: flex;
            position: relative;
            height: fit-content;
            align-items: center;
            justify-content: center;
            transition: .5s;

            &:hover {
                .menu {
                    display: flex;
                    animation: appearance .5s;
                }
            }
        }
    }

    &__icon {
        width: 20px;
        height: 20px;

        &-normal {
            opacity: 1;
        }

        &-hover {
            opacity: 0;
        }

        img {
            width: inherit;
            height: inherit;
            position: absolute;
        }
    }

    &__notification {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__ava {
        width: 32px;
        height: 32px;
        display: flex;

        img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
        }
    }

    &__button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__menu {
        position: absolute;
        top: 100%;
        padding: 16px 0 0;
        right: 0;
        display: flex;
    }
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: $other-notification;
    border-radius: 50%;
    font-family: 'Inter';
    font-style: normal;
    color: $emotion-white;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
}
.topup-notification {
    @extend .notification;
    background-color: inherit;
    align-items: inherit;
}
.delete-notification-container{
    width: 25px;
    height: 25px;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
}
.delete-notification-image{
    width: 25px;
    height: 25px;
}

.end {
    stroke: #066F0A !important;
}

.menu {
    display: none;
    animation: disappearance .5s forwards;
    flex-direction: column;
    border-radius: 16px;
    background: $bg-blur;
    overflow: hidden;
    backdrop-filter: blur(64px);
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.36);

    &__divider {
        width: 100%;
        padding: 0 12px;
        display: flex;
        justify-content: center;

        div {
            width: 100%;
            height: 1px;
            background-color: $bg-border;
            border-radius: 1px;
        }
    }

    &__item {
        cursor: pointer;
        background: $bg-blur;
        backdrop-filter: blur(64px);
        width: 188px;
        min-height: 40px;
        padding: 0 12px 0 28px;
        align-items: center;
        display: flex;
        gap: 12px;
        transition: .2s;
        color: $content-low;

        &:hover {
            background-color: $bg-accent;
            transition: .2s;
            color: $content-major;

            .menu__icon-normal {
                opacity: 0;
                transition: .5s;
            }

            .menu__icon-hover {
                opacity: 1;
                transition: .5s;
            }
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $content-low;

        &.big {
            width: 18px;
            height: 18px;
        }

        img {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 1px;
            position: absolute;
            transition: .5s;
        }

        .menu__icon-normal {
            opacity: 1;
        }

        .menu__icon-hover {
            opacity: 0;
        }
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        font-feature-settings: 'pnum' on, 'lnum' on;

        color: inherit;

        p {
            cursor: pointer;
        }
    }
}

.nav-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__texts {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__time {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        color: $content-low;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: $content-medium;
    }
}

.jump {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    display: none;
    justify-content: center;
    padding-top: 108px;
    background-color: rgba(0, 0, 0, 0.72);

    &__row {
        display: flex;
        width: 642px;
        height: calc(100vh - 108px);
        background: rgba(20, 23, 31, 0.64);
        box-shadow: 0px 0.3px 0px #283346;
        backdrop-filter: blur(36px);
        background: #14171F;
        overflow: hidden;
        border-radius: 24px 24px 0px 0px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.72);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    &.big {
        .modal__row {
            max-width: 666px;
        }
    }

    &__row {
        max-width: 642px;
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        max-height: 731.719px;
        padding-bottom: 48px;
        align-items: center;
        background: $emotion-black;
        border-radius: 24px;
        overflow: hidden;

        &.small {
            max-height: 597.172px;
        }
    }

    &__head {
        width: 100%;
        height: 56px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: rgba(20, 23, 31, 0.64);
        box-shadow: 0px 0.3px 0px #283346;
        backdrop-filter: blur(36px);
    }

    &__body {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
        gap: 36px;
        padding: 0 36px;
        align-items: center;

        &::-webkit-scrollbar {
            width: 8px;
            /* ширина всей полосы прокрутки */
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            /* цвет зоны отслеживания */
        }

        &::-webkit-scrollbar-thumb {
            background-color: $bg-secondary;
            border-radius: 15px;
            /* цвет бегунка */
        }
    }

    &__frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 100%;
    }

    &__error {
        font-family: Inter;
        font-size: 17px;
        font-weight: 400;
        line-height: 23.8px;
        text-align: center;
        color: $other-red;
    }

    &__icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;

        &.small {
            width: 32px;
            height: 32px;
        }

        &.middle {
            width: 128px;
            height: 72px;
        }

        &.big {
            width: 144px;
            height: 144px;
        }

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    &__space {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 36px;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    &__title {
        color: $content-major;
        text-align: center;
        font-family: 'Inter';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    &__text {
        color: $content-medium;
        text-align: center;
        font-family: 'Inter';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    &__link {
        color: $content-link;
        cursor: pointer;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &.end {
            align-self: flex-end;
        }
    }
}

.main {
    z-index: 1;
    padding-top: $header-height + 24px;
    width: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;


    &__container {
        padding: 0 48px;
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        flex-direction: column;
    }
}

.list {
    width: 100%;
    z-index: 500;

    &__row,
    .scroll {
        display: flex;
        flex-wrap: wrap;
        padding: 17px 0;
        align-items: flex-start;
        overflow-x: scroll;
        overflow-y: visible;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: inherit;
            height: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: $bg-secondary;
            border-radius: 5px;
            height: 100%;
        }

        &::-webkit-scrollbar {
            background: transparent;
        }
    }
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    justify-content: center;
    padding: 0;

    &.scroll {
        padding-left: 48px;
    }

    &__line {
        display: flex;
        width: 0px;
        transition: .5s;
        height: 1px;
        background-color: $content-major;
        border-radius: 1px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: $content-medium;
        cursor: pointer;
        gap: 12px;
        padding: 0 12px;
        transition: .5s;

        &-active {
            color: $content-major;

            .navbar__text {
                p {
                    color: rgba(27, 174, 49, 1);
                }
            }

            .navbar__icon {
                &-normal {
                    opacity: 0;
                }

                &-hover {
                    opacity: 1;
                }
            }

            .navbar__line {
                width: 32px;
                transition: .5s;
            }
        }

        &:hover {
            transition: .5s;
            color: $content-major;

            .navbar__icon {
                &-normal {
                    opacity: 0;
                    transition: .5s;
                }

                &-hover {
                    opacity: 1;
                    transition: .5s;
                }
            }

            .navbar__text {
                p {
                    color: rgba(27, 174, 49, 1);
                }
            }

            .navbar__line {
                width: 32px;
                transition: .5s;
            }
        }

        &-divider {
            padding: 0 !important;
            cursor: default !important;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
    }

    &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &-normal {
            opacity: 1;
            transition: .5s;
        }

        &-hover {
            opacity: 0;
            transition: .5s;
        }

        img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__text {
        display: flex;
        color: inherit;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        white-space: nowrap;

        p {
            width: 100%;
            color: inherit;
        }
    }

    &__divider {
        height: 55px;
        padding: 4px 0 19px;
        display: flex;

        span {
            display: flex;
            height: 100%;
            width: 1px;
            background: $bg-border;
            border-radius: 1px;
        }
    }
}

.block {
    display: flex;
    width: 100%;
    height: 200vh;
    background-color: red;
}

.disclaimer {
    min-width: 350px;
    padding: 24px;
    display: flex;
    z-index: 1005;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 40px;
    left: 40px;
    background: $other-red;
    border-radius: 15px;

    &.close {
        display: none;
    }

    &__row {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 12px;
    }

    &__icon {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 30px;
            color: $content-major;
        }
    }

    &__text {
        color: $content-major;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    &__close {
        cursor: pointer;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 30px;
            color: $content-major;
        }
    }
}

.game-card {
    cursor: pointer;
    min-width: 236px;
    height: 296px;
    position: relative;
    display: flex;
    border-radius: 20px;
    overflow: hidden;

    &:hover {
        .game-card__img {
            width: 260px;
            height: 324px;
            transition: .3s ease-in-out;
        }

        .game-card__title {
            color: $content-major;
            transition: .3s color ease-in-out;
        }
    }

    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: .2s ease-in-out;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__row {
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 236px;
        height: 296px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        isolation: isolate;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
        transition: .2s ease-in-out;
    }

    &.no-hover {
        &:hover {
            transform: none;
            transition: none;

            .game-card__row {
                width: 270px;
                height: 378px;
            }
        }
    }

    &.big {
        min-width: 270px;
        height: 378px;

        .game-card__row {
            width: 270px;
            height: 378px;
        }

        .game-card__price {
            .discount {
                font-size: 19px;
                color: $other-green;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        gap: 4px;
        width: 100%;
        height: 70px;
        background: $bg-blur;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
        width: 100%;
        overflow: hidden;
        text-wrap: nowrap;
    }

    &__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-major;

        .discount {
            color: $other-green;
        }

        &-old {
            text-decoration-line: line-through;
            font-weight: 400;
        }

        &-now {
            font-weight: 600;
        }

        &-value {
            font-weight: 600;
            color: $content-low;
        }
    }

    &__notifications {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 9px;
    }

    &__fav {
        top: 8px;
        right: 8px;
    }
}

.game-form {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    min-width: 232px;
    width: 232px;

    &:hover {
        .game-form__img {
            height: 310px;
            width: 243px;
            transition: .2s ease-in-out;
        }
    }

    &__preview {
        display: flex;
        position: relative;
        isolation: isolate;
        width: 100%;
        height: 296px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        overflow: hidden;
        z-index: 300;
    }

    &__img {
        position: absolute;
        z-index: 300;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
        transition: .2s ease-in-out;

        img {
            display: flex;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
    }

    &__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-size: 15px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-major;

        .discount {
            color: $other-green;
        }

        &-old {
            text-decoration-line: line-through;
            font-weight: 400;
        }

        &-now {
            font-weight: 600;
        }

        &-value {
            font-weight: 600;
            color: $content-low;
        }
    }

    &__notifications {
        position: absolute;
        top: 8px;
        z-index: 301;
        left: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 9px;
    }

    &__fav {
        top: 8px;
        z-index: 301;
        right: 8px;
    }
}

.status {
    display: flex;
    padding: 1px 8px;
    border-radius: 11px;
    color: $emotion-white;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    background: $other-green;

    &.yellow {
        background: $other-yellow;
    }

    &.red {
        background: $other-red;
    }
}

.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $other-green;
    border: 2px solid rgba(33, 202, 58, 0.32);
    background-clip: padding-box;
    padding: 3.5px 8px;
    border-radius: 24px;

    &-red {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3.5px 8px;
        border-radius: 24px;
        background: $other-red;
        border: 2px solid rgba(245, 92, 69, 0.32);
        background-clip: padding-box;
    }

    &-yellow {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $other-yellow;
        border: 2px solid rgba(252, 192, 38, 0.32);
        background-clip: padding-box;
        padding: 3.5px 8px;
        border-radius: 24px;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $emotion-white;
    }
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: $content-medium;
}

.footer {
    padding-top: 80px;
    position: relative;
    z-index: 1;

    &__row {
        //display: flex;
        justify-content: space-between;
        padding: 20px 48px;
        flex-wrap: wrap;
        align-items: center;
        background-color: $bg-secondary;

        &>.footer__content {
            border-top: 1px solid $bg-accent;
            padding: 24px 0;
            justify-content: center;

            &:first-of-type {
                justify-content: space-between;
                border-top: none;
                padding: 0 0 12px;
            }
        }
    }

    &__content {
        display: flex;
        gap: 24px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding: 12px 0;

        .footer__content {
            width: auto;
        }

        &.space {
            gap: 36px;
        }

        .logo {
            padding: 0;
        }

        .logo__row {
            padding: 0;

            img {
                width: 96px;
                padding: 0;
            }
        }
    }

    &__icons {
        display: flex;
        gap: 24px;
        align-items: center;
    }

    &__link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $emotion-green;

        &.border {
            border-left: 1px solid $bg-accent;
            padding: 0 0 0 24px;

            &:first-of-type {
                border: none;
            }
        }
    }

    &__icon {
        width: 24px;
        cursor: pointer;
        height: 24px;

        img {
            width: 100%;
        }
    }

    &__copy {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-low;
    }
}

.popup {
    position: fixed;
    z-index: 700;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 400px;
    width: 100%;
    display: flex;
    padding: 9.5px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 48px;
    opacity: 1;
    background: $other-red;
    animation: spawn .3s 1 forwards linear;


    &__text {
        font-family: Inter;
        flex-grow: 1;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $content-major;
    }

    &__icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 20px;
            color: $content-major;
        }
    }
}

@keyframes spawn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.videogames {
    width: 100%;

    &__row {
        display: flex;
        column-gap: 24px;
        row-gap: 40px;
        // два варианта решения адаптивный блоков
        // justify-content: space-between;
        flex-wrap: wrap;
    }
}

.content {
    max-width: 888px;
    width: 100%;
    overflow-x: visible;
    margin: 0 auto;
    padding-top: $header-height + 24px;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.mobile {
        max-width: 578px;
    }

    &__container {
        display: flex;
        flex-direction: column;

        &.start {

            align-items: flex-start;
        }
    }

    .path {
        padding: 0;
        margin: 0;
    }

    .games {
        margin-top: 48px;
        position: relative;
        width: calc(100% + ((100vw - 100%) / 2));

        &__nav {
            max-width: 904px;
            width: 100%;
            align-self: flex-start;
            padding-right: 16px;
        }

        .scroll {
            position: absolute;
            top: -17px;
            width: 100vw;
            left: calc(-1 * ((100vw - 100%)));
            padding-left: calc(((100vw - 100%)));
            padding-right: calc(((100vw - 100%)));
        }
    }

    .scroll {
        overflow-x: scroll;
        overflow-y: visible;
    }
}

.files {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.upload {
    width: 100%;
    height: 62px;
    display: flex;
    padding: 12px;

    input {
        display: none;
    }

    label {
        display: flex;
        justify-content: center;
        padding: 10px;
        width: 100%;
        border-radius: 8px;
        border: 1px dashed $content-link;
        background: rgba(42, 122, 242, 0.02);
    }

    &__content {
        display: flex;
        gap: 10px;
    }

    &__icon {
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    &__text {
        color: $content-link;
        font-family: 'Inter';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
}

.file {
    display: flex;
    padding: 4px 0;
    padding-left: 12px;
    padding-right: 8px;
    border-radius: 8px;
    width: 100%;
    gap: 16px;
    background: $bg-secondary;

    &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.small {
            padding: 6px;
        }

        img {
            object-fit: contain;
            width: 100%;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-start;
    }

    &__title {
        color: $content-medium;
        font-family: 'Inter';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    &__size {
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: flex;
        gap: 2px;
        color: $content-hint;

        h1 {
            font-size: inherit;
            font-weight: 500;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: $bg-border;
}

.categories {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;

    &.grid {
        margin-top: 24px;
        margin-bottom: 26px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
    }

    &__item {
        cursor: pointer;
        min-width: 318px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 178px;
        background-image: linear-gradient(0deg, #1D2029, #1D2029),
            linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
        border-radius: 24px;
        transition: .2s ease-in-out;

        &.full {
            grid-column: 1 / -1;
        }

        &:hover {
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                linear-gradient(102.96deg, #7CB516 0%, #158B1A 100%),
                linear-gradient(102.96deg, #7CB516 0%, #066F0A 100%);
            transition: .2s ease-in-out;

            .categories__icon {
                &-normal {
                    opacity: 0;
                    transition: .2s ease-in-out;
                }

                &-hover {
                    opacity: 1;
                    transition: .2s ease-in-out;
                }
            }

            .categories__text {
                color: $emotion-white;
                transition: .2s ease-in-out;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    &__icon {
        width: 64px;
        height: 64px;
        position: relative;
        padding: 8px;

        img {
            position: absolute;
            object-fit: contain;
            width: 100%;
            max-height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: .2s ease-in-out;
        }

        &-normal {
            opacity: 1;
            transition: .2s ease-in-out;
        }

        &-hover {
            opacity: 0;
            transition: .2s ease-in-out;
        }
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $content-medium;
        transition: .2s ease-in-out;
    }
}

.steam-input-wrapper{
    display: flex;
    align-items: center;
}

.currency-toggle{
    display: flex;
    gap: 2px;
    margin-left: 5px;
    justify-content: center; /* Центрирование элементов по горизонтали */
    align-items: center; /* Центрирование элементов по вертикали */

    &__element{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
        display: flex; /* Используем flex, чтобы выровнять текст по центру */
        justify-content: center; /* Горизонтальное центрирование текста */
        align-items: center; /* Вертикальное центрирование текста */
        cursor: pointer;
        //border: 1px solid #7c7979;
        border-radius: 10px;

        width: 50px;
        height: 50px;

        &.active{

            background-color: #656565;
            transition: .2s
        }
    }
}




.lng {
    cursor: pointer;
    display: flex;
    gap: 8px;
    position: relative;

    &:hover {
        .menu {
            display: flex;
            animation: appearance .5s;
        }
    }

    &__active {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
        color: $content-low;
    }

    &__body {
        position: absolute;
        top: 100%;
        padding: 16px 0 0;
        right: 0;
        display: flex;

        & .menu {
            &__item {
                width: auto;
                padding: 0 28px;
            }

            &__text {
                p {
                    text-transform: uppercase;
                }
            }
        }
    }

    &__button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media all and (min-width: 2000px) {
    .game-form {
        min-width: 12.198%;
        width: 12.198%;
        position: relative;
        padding-bottom: 18.31%;
        height: 80px;
        justify-content: flex-end;

        &__preview {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            height: 85%;
            transform: translate(-50%, 0);
        }

        &__content {
            position: absolute;
            top: calc(85% + 8px);
            left: 0;
        }

        &__title {
            font-size: .78vw;
        }

        &__fav {
            width: 17.248%;
            padding-bottom: 17.248%;

            .arrow-content {
                position: absolute;
                padding: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }

            .arrow-icon {
                width: 50%;
                height: 50%;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .categories {
        &__item {
            min-width: 16.72%;
            width: 16.72%;
            position: relative;
            padding-bottom: 9.359%;
        }

        &__icon {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            padding: 0;
            height: 58.4%;
            transform: translateX(-50%);
        }

        &__text {
            position: absolute;
            bottom: 0;
            font-size: 1.2vw;
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20.13%;
            padding-bottom: 34.466%;
            transform: translate(-50%, -50%);
        }

        &__text {
            text-wrap: nowrap;
        }
    }

    .banner {
        &__arrow {
            width: 2.1035%;
            padding-bottom: 2.1035%;
            border-radius: 50%;

            .arrow-content {
                position: absolute;
                border-radius: 50%;
                padding: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }

            .arrow-icon {
                width: 50%;
                height: 50%;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .navbar {
        &__item {
            height: 100%;
            width: fit-content;
            padding-bottom: 3.045%;
            position: relative;
        }

        &__line {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &__info {
            position: absolute;
            height: 78.06%;
            justify-content: flex-end;
        }

        &__icon {
            position: absolute;
            top: 0;
            height: 51.97%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &__text {
            p {
                font-size: .8vw;
            }
        }
    }

    .footer {
        &__link {
            font-size: .65vw;
        }

        &__copy {
            font-size: .8vw;
        }

        & .logo {
            width: 5.064%;

            &__row {
                position: relative;
                width: 100%;
                padding-bottom: 25%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .header {
        height: 5.988%;

        &__container {
            padding: 0 48px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
@media all and (max-width: 1400px){
    .background-container{
        .steam-info-wrapper{
            width: 40%;
        }
    }
    .categories{
        &.grid{
            grid-template-columns: repeat(3, 0.7fr);
        }
    }

}

@media all and (max-width: 1075px) {
    .background-container{
        //flex-direction: column;
        align-items: center;
    }
    .steam-info-wrapper{
        width: 60%;
    }
    .steam-payment-wrapper{
        width: 60%;
    }
    .payments{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footer {
        padding-top: 32px;

        &__row {
            flex-direction: column;
            //gap: 24px;
            padding: 20px 16px;
            justify-content: center;
        }

        &__content {
            &.space {
                width: 100%;
                justify-content: space-between;

                &>.footer__content:nth-of-type(3) {
                    display: flex;
                }
            }
        }

        &__icons {
            display: none;
        }
    }

    .window {
        &__row {
            grid-template-columns: 1fr;
        }
    }

    .categories {
        &.grid {
            grid-template-columns: repeat(2, 0.5fr);
            margin-bottom: 48px + 56px;
        }

        &__item {
            width: 100%;
        }
    }
}

@media all and (max-width: 1000px) {
    .header {
        padding: 0 10px;
        box-shadow: 0px 0.3px 0px $bg-border;

        &__container {
            gap: 24px;
        }

        .search {
            width: auto;
            flex-grow: 1;
        }

        &__navigation {
            display: flex;
        }
        &__navigation > :not(.lng){
            display: none;
        }
        &__icon {
            display: none;
        }

        &__logo {
            .logo {

                &__line,
                &__menu {
                    display: none;
                }
            }
        }
    }

    .nav-bottom {
        display: block;
    }
}

@media all and (max-width: 930px) {
    .background-container{
        .steam-info-wrapper{
            width: 60%;
        }
    }

    .videogames {
        &__row {
            justify-content: center;
        }
    }

    .jump {
        display: flex;
    }

    .content {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media all and (max-width: 834px) {
    .game-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        min-width: 200px;
        width: 200px;

        &:hover {
            .game-form__img {
                height: 310px;
                width: 243px;
                transition: .2s ease-in-out;
            }
        }

        &__preview {
            display: flex;
            position: relative;
            isolation: isolate;
            width: 100%;
            height: 200px;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            overflow: hidden;
            z-index: 300;
        }
        &__fav{
            top: 6px;
            right: 9px;
        }

    }
    .arrow{
        &-content {
            width: 35px;
            height: 35px;
            padding: 8px;
            border-radius: 48px;
        }
    }


        .footer {
        padding-bottom: 60px;
    }

    .empty {
        &__icon {
            font-size: 144px;

            img {
                width: 144px;
                height: 144px;
            }
        }

        &__title {
            font-size: 28px;
        }
    }

    .list {
        .scroll {
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .path {
        padding: 0 16px;
    }

    .navbar {
        padding: 16px;

        &.scroll {
            padding-left: 16px;
        }
    }

    .main {
        &__container {
            padding: 0 17px;
        }
    }

    .footer__content:last-child{
        display: none;
    }
    //#discounts,
    //#best-games,
    //#categories {
    //    display: none;
    //}
}

@media all and (max-width: 700px) {
    .background-container{
        .steam-info-wrapper{
            width: 80%;
        }
    }
    .footer__copy{
        font-size: 15px;
    }
    .game-form {
        gap: 5px;
        min-width: 170px;
        width: 170px;

        &:hover {
            .game-form__img {
                height: 200px;
                width: 190px;
            }
        }

        &__preview {
            height: 170px;
            border-radius: 40px;
        }
    }

    .categories {
        &.grid {
            grid-template-columns: repeat(1, 0.5fr);
        }
    }

    .bought-card {
        grid-template-columns: auto 1fr;
        justify-items: flex-start;

        &__btn {
            grid-column: 2;
        }
    }
}

@media all and (max-width: 642px) {
    .footer__copy{
        font-size: 12px;
    }
    .videogames {
        &__row {
            justify-content: center;
        }
    }
}

@media all and (max-width: 550px) {
    .game-form {
        min-width: 140px;
        width: 140px;

        &:hover {
            .game-form__img {
                height: 160px;
                width: 160px;
            }
        }

        &__preview {
            height: 140px;
            border-radius: 20%;
        }
        &__fav{
            top: 6px;
            right: 5%;
        }
    }


    .empty {
        &__icon {
            font-size: 128px;
        }

        &__title {
            font-size: 21px;
        }
    }

    .header {
        height: 56px;

        &__container {
            gap: 10px;
        }

        .burger {
            display: flex;
        }

        .logo {
            display: none;
        }
    }

    .categories {
        &.grid {
            //grid-template-columns: 1fr;
            gap: 12px;
            margin-top: 16px;
        }
    }

    .navbar {
        padding: 12px;
    }

    .nav-bottom {
        height: auto;
    }

    .footer {
        padding-bottom: 56px;

        &__row {
            flex-direction: column;
            gap: 24px;
            align-items: flex-start;
        }

        &__content {
            //flex-direction: column;
            align-items: flex-start;
            column-gap: 24px;

            &.space {
                &>.footer__content:nth-of-type(3) {
                    display: flex;
                    flex-direction: row;
                    row-gap: 24px;
                }
            }
        }
    }
}
@media all and (max-width: 500px){
    .btn{
        display: flex;
    }

    .footer {
        padding-bottom: 26px;

        &__row {
            flex-direction: column;
            gap: 15px;
            align-items: flex-start;
        }

        &__content {
            //align-items: flex-start;
            column-gap: 15px;

            &.space {
                &>.footer__content:nth-of-type(3) {
                    display: flex;
                    flex-direction: row;
                    row-gap: 24px;
                }
            }
        }
    }

    .game-form {
        min-width: 100px;
        width: 100px;

        &:hover {
            .game-form__img {
                height: 120px;
                width: 120px;
            }
        }

        &__preview {
            height: 100px;
            border-radius: 20%;
        }
    }
    .arrow{
        &-content {
            width: 30px;
            height: 30px;
            padding: 8px;
            border-radius: 48px;
        }
    }
}

@media all and (max-width: 375px) {
    .background-container{
        .steam-info-wrapper{
            width: 90%;
        }
    }
    .game-form {
        min-width: 90px;
        width: 90px;

        &:hover {
            .game-form__img {
                height: 120px;
                width: 120px;
            }
        }

        &__preview {
            height: 90px;
            border-radius: 20%;
        }
    }

    .nav-line {
        &__text {
            font-size: 19px;
        }
    }

    .navbar {
        &.scroll {
            padding-left: 12px;
        }
    }
}




input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
{
    -webkit-box-shadow: 0 0 0px 1000px #242935 inset; /* Цвет фона, под цвет формы */
    -webkit-text-fill-color: white; /* Цвет текста */
    margin: 7px 20px 2px 15px;
    caret-color: white;
    height: 15%;
}